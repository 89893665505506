<template>
  <form class="grid mw720 ma h100vh flex fww aic acc" v-if="!token" @submit.prevent="handleForgotPassword">
    <div class="grid__main p2">
      <div class="grid__col">
        <h3 class="grid__title">Reset Password</h3>
      </div>
      <div class="grid__col flex fww">
        <label class="grid__input--label" for="userEmail">User email</label>
        <router-link class="mla pointer" :to="{name: 'Login'}">I remember the password</router-link>
        <input class="grid__input" name="userEmail" type="email" id="userEmail"
               placeholder="john.doe@example.com" v-model="userEmail" required>
      </div>
      <div class="grid__col">
        <button :disabled="submitted" :class="submitted ? 'is-load' : ''">Submit</button>
        <p class="grid__error">{{ errorMessage }}</p>
      </div>
    </div>
  </form>
  <form class="grid mw720 ma h100vh flex fww aic acc" v-else @submit.prevent="handleChangePasswordByToken">
    <div class="grid__main p2">
      <div class="grid__col">
        <h3 class="grid__title">Set Password</h3>
      </div>
      <PasswordSetter
        :settings="{
          rememberLink: true,
          user_new_pass: 'User password',
          user_new_pass_confirm: 'User password confirm',
        }"
        @update-password="onUpdatePassword"
      />
      <div class="grid__col">
        <button :disabled="submitted" :class="submitted ? 'is-load' : ''">Submit</button>
        <p class="grid__error">{{ errorMessage }}</p>
      </div>
    </div>
  </form>
</template>

<script>
import {authService} from "@/_services";
import PasswordSetter from "@/components/password-setter/PasswordSetter";

export default {
  components: {
    PasswordSetter,
  },
  data: () => {
    return {
      userEmail: '',
      user_new_pass: '',
      user_new_pass_confirm: '',
      submitted: false,
      token: null,
      referrer: window.location.href,
      errorMessage: '',
    }
  },
  methods: {
    onUpdatePassword( data ) {
      for ( let key in data ) {
        this[key] = data[key];
      }
    },
    handleForgotPassword() {
      if (!this.userEmail) {
        return;
      }
      this.submitted = true;
      authService.forgotPassword(this.userEmail, this.referrer).then(
        payload => {
          this.response = payload;
        }
      ).catch(error => {
        if ( error.response.data ) {
          this.errorMessage = error.response.data.message
        }
      }).finally(() => {
        this.submitted = false;
      });
    },
    handleChangePasswordByToken() {
      if (!(this.token && this.user_new_pass && this.user_new_pass_confirm)) {
        return;
      }
      this.submitted = true;
      authService.changePasswordByToken(this.token, this.user_new_pass, this.user_new_pass_confirm).then(
          payload => {
            this.user_new_pass = '';
            this.user_new_pass_confirm = '';
            this.response = payload;
          }
      ).catch(error => {
        if ( error.response.data ) {
          this.errorMessage = error.response.data.message
        }
      }).finally(() => {
        this.submitted = false;
      });
    },
  },
  watch: {
    userEmail: function() {
      this.errorMessage = ''
    },
  },
  created() {
    this.token = this.$route.query.token;
  }
};
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>